import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackProcess from "../images/svg/back-alliances.svg"
import Img from "gatsby-image"
import pageData from "../data/pageData"
import framework from "../assets/framework"

const Proceso = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ProcessImage {
      file(name: { eq: "proceso" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <SEO title="Proceso" />
      <Styled>
        <BackProcess className="backImage" />
        <div className="framework">
          <h2 className="title-framework">Framework de Transformación</h2>
          <div className="content-framework">
            <p>
              Nuestra Estrategia Integral de Transformación Digital se centra
              en:
            </p>
          </div>
          <div className="conteiner-framework">
            <div className="estrategia">
              <div className="digital">
                <p>DIGITAL TRANSFORMATION</p>
              </div>
              <div className="marketing">
                <p>MARKETING SOLUTIONS</p>
              </div>
            </div>
            <div className="relleno">
              <p>Estrategia</p>
            </div>
          </div>
          <div className="conteiner-framework-2">
            <div className="customer">
              <div className="contenedor-1">
                <p>CUSTOMER CENTRICITY</p>
              </div>
              <div className="contenedor-2">
                <div className="metrically">
                  <img src={framework.img1} alt='Framework' />
                </div>
                <div className="ux">
                  <img src={framework.img7} alt='Framework' />
                </div>
              </div>
              <div className="contenedor-3">
                <p>DIGITAL SOLUTIONS</p>
                <div className="back">
                  <img src={framework.img5} alt='Framework' />
                </div>
              </div>
              <div className="contenedor-4">
                <p>SOCIAL MEDIA STRATEGY</p>
              </div>
              <div className="contenedor-5">
                <p>CUSTOMER HEAR & LEARN</p>
              </div>
              <div className="contenedor-6">
                <div className="title1">
                  <p>INNOVATIION</p>
                </div>
                <div className="title2">
                  <div className="vent">
                    <img src={framework.img8} alt='Framework' />
                  </div>
                  <div className="innova">
                    <p>TELEMEDICINA</p>
                  </div>
                </div>
                <div className="title3">
                  <div className="inter">
                    <p>INTERPORABILIDAD</p>
                  </div>
                  <div className="hospital">
                    <p>HOSPITALARIA</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relleno-2">
              <p>Customer Centricity</p>
            </div>
          </div>
          <div className="conteiner-framework-3">
            <div className="digital-business">
              <div className="businesscontenedor-1">
                <p>DIGITAL BUSINESS OPERATION</p>
              </div>
              <div className="businesscontenedor-2">
                <p>DIGITAL MINDSET</p>
                <div className="digital-none">
                  <p>
                    Adaptar los procesos y perfiles que se requieran para operar
                    bajo el nuevo modelo.
                  </p>
                </div>
              </div>
              <div className="businesscontenedor-3">
                <div className="business-title1">
                  <p>CREATE NEW BUSINESS MODEL</p>
                </div>
                <div className="business-title2">
                  <p>INGENIERÍA DE SOFTWARE</p>
                </div>
              </div>
              <div className="businesscontenedor-4">
                <p>BUSINESS TRANSFORMATION</p>
              </div>
              <div className="businesscontenedor-5">
                <p>INTERNAL CULTURE</p>
                <div className="internal-none">
                  <p>
                    Identificar los componentes aceleradores y retractores de la
                    transformación
                  </p>
                </div>
              </div>
              <div className="businesscontenedor-6">
                <div className="business-title3">
                  <p>RE-INVENT EXISTING BUSINESS MODEL</p>
                </div>
                <div className="business-title4">
                  <p>INGENIERÍA DE SOFTWARE</p>
                </div>
              </div>
              <div className="businesscontenedor-7">
                <p>TECHNOLOGY ECOSYSTEM</p>
              </div>
              <div className="businesscontenedor-8">
                <p>INTERNAL OPERATION SYSTEMS</p>
                <div className="internaloperation-none">
                  <p>
                    ¿Qué tan listos están nuestros sistemas para soportar la
                    estrategia?
                  </p>
                </div>
              </div>
              <div className="businesscontenedor-9">
                <div className="business-title5">
                  <p>CUSTOMER-FACING SYSTEMS</p>
                </div>
                <div className="sales">
                  <img src={framework.img6} alt='Framework' />
                </div>
              </div>
            </div>
            <div className="relleno-3">
              <p>Pilares del negocio</p>
            </div>
          </div>
          <div className="conteiner-framework-4">
            <div className="information">
              <div className="informationcontenedor-1">
                <p>INFORMATION MANAGEMENT & DATA ANALYTICS</p>
              </div>
              <div className="informationcontenedor-2">
                <div className="bimov">
                  <img src={framework.img2} alt='Framework' />
                </div>
                <div className="lakeit">
                  <img src={framework.img4} alt='Framework' />
                </div>
              </div>
              <div className="informationcontenedor-3">
                <p>DIGITALIZATION & INTEROPERABILITY</p>
              </div>
              <div className="informationcontenedor-4">
                <p>INGENIERÍA DE SOFTWARE QA & TESTING FACTORY</p>
              </div>
              <div className="informationcontenedor-5">
                <p>INFRASTRUCTURE & CONNECTIVITY</p>
              </div>
              <div className="informationcontenedor-6">
                <div className="bleo">
                  <img src={framework.img3} alt='Framework' />
                </div>
                <div className="information-title1">
                  <p>CLOUD SERVICES</p>
                </div>
              </div>
            </div>
            <div className="relleno-4">
              <p>Bases tecnológicas</p>
            </div>
          </div>
        </div>
        <div className="process">
          <h2 className="title">{pageData.process.title}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: pageData.process.about }}
          ></div>
        </div>
        <div className="img-container">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={pageData.process.title}
          />
        </div>
      </Styled>
    </Layout>
  )
}

export default Proceso

const Styled = styled.section`
  position: relative;
  padding: 2rem 0 4rem;
  overflow-x: hidden;
  .backImage{
    position: absolute;
    top: -100px;
    height: auto;
    z-index: -1;
    right: -40px;
    width: 140px;
    transition: all linear .5s;
    #alianzas {
      fill: #09A2AD;
    }
    @media screen and (min-width: 600px){
      width: 200px;
      top: -100px;
      right: -20px;
    }
    @media screen and (min-width: 1100px){
      top: -140px;
      width: 300px;
      right: -50px;
    }
  }

  .title-framework{
    color: #000000;
    font-family: 'Questrial', sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 1rem;
    margin: 0px;
    @media screen and (min-width: 1100px){
      font-size: 28px;
      line-height: 29px;
      display: flex;
      justify-content: center;
    }
  }

  .content-framework p{
    color: #777777;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
    padding: 1rem;
    @media screen and (min-width: 1100px){
      font-size: 18px;
      line-height: 36px;
      display: flex;
      justify-content: center;
      padding: 2rem 2rem 5rem 2rem;
    }
  }
  .digital-none, .internal-none, .internaloperation-none{
    display: none;
  }

  .conteiner-framework-2,.conteiner-framework, .conteiner-framework-3, .conteiner-framework-4{
    display: grid;
    grid-template-columns: 90% 10%;
    border-radius: 16px;
    margin: 1rem;
    @media screen and (min-width: 1100px){
      grid-template-columns: 95% 5%;
    }
  }
  
  .conteiner-framework{
    height: 7rem;
    border: 1px solid #979797;
  }
  
  .conteiner-framework-2{
    border: 1px solid #6C99C3;
  }

  .conteiner-framework-3{
    border: 1px solid #809E6E;
  }

  .conteiner-framework-4{
    border: 1px solid #E1BB43;
  }

  .relleno{
    border-radius: 0 16px 16px 0;
    background-color: #D8D8D8;
  }

  .relleno-2{
    border-radius: 0 16px 16px 0;
    background-color: #9EC4E7;
  }

  .relleno-3{
    border-radius: 0 16px 16px 0;
    background-color: #A8D28E;
  }

  .relleno-4{
    border-radius: 0 16px 16px 0;
    background-color: #FFDA65;
  }
  
  .relleno p, .relleno-2 p, .relleno-3 p, .relleno-4 p{
    transform: rotate(-90deg);
    color: #000000;
    font-family: "Century Gothic", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.13px;
    line-height: 22px;
    margin: 0px;
    @media screen and (max-width: 350px){
      font-size: 10px;
    }
  }

  .relleno p{
    height: 6.4rem;
    width: 6.4rem;
    @media screen and (max-width: 350px){
      height: 5.4rem;
      width: 5.4rem;
    }
    @media screen and (min-width: 1100px){
      height: 6rem;
      width: 7rem;
    }
  }

  .relleno-2 p{
    height: 24rem;
    width: 24rem;
    @media screen and (min-width: 1100px){
      height: 12rem;
      width: 13rem;
    }
  }

  .relleno-3 p{
    height: 24rem;
    width: 24rem;
    @media screen and (min-width: 1100px){
      height: 14rem;
      width: 15rem
    }
  }

  .relleno-4 p{
    height: 19rem;
    width: 19rem;
    @media screen and (min-width: 1100px){
      height: 12rem;
      width: 13rem;
    }
  }

  .marketing, .contenedor-2, .contenedor-3, .contenedor-4, .contenedor-5, .contenedor-6, .businesscontenedor-2, 
  .businesscontenedor-3, .businesscontenedor-5, .businesscontenedor-6, .businesscontenedor-8, .businesscontenedor-9, 
  .informationcontenedor-2, .informationcontenedor-6, .informationcontenedor-4{
    border: 1px solid #979797;
    border-radius: 10px;
    min-width: 90%;
    margin: 0px 10px 10px 10px;
    display:flex;
    justify-content: flex-start;
    @media screen and (min-width: 1100px){
      justify-content: center;
    }

  }



  .businesscontenedor-2, .businesscontenedor-3, .businesscontenedor-5, .businesscontenedor-6, 
  .businesscontenedor-8, .businesscontenedor-9{
    border: 1px solid #809E6E;
  }

  .informationcontenedor-2, .informationcontenedor-6, .informationcontenedor-4{
    border: 1px solid #E1BB43;
  }

  .businesscontenedor-9, .informationcontenedor-2, .informationcontenedor-6{
    justify-content: space-evenly;
  }

  .contenedor-2{
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 0.5rem 0.5rem 1rem;
    border: 1px solid #6C99C3;
  }
  .contenedor-3, .contenedor-6{
    flex-direction: column;
    border: 1px solid #6C99C3;
    padding: 1rem 0.5rem 1rem 1rem;
  }

  .businesscontenedor-3, .businesscontenedor-6 {
    flex-direction: column;
  }

  .contenedor-4, .contenedor-5{
    border: 1px solid #6C99C3;
  }
  
  .metrically img, .ux img, .back img, .vent img, .bimov img, .lakeit img, .bleo img{
    margin: 0px;
    max-width: 80%;
    padding: 0.5rem 0rem 0rem 0rem;
  }

  .marketing p{
    color: #000000;
    font-family: "Century Gothic", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0.5rem 1rem;
    margin: 0px;
    @media screen and (max-width: 350px){
      font-size: 8px;
    }
    @media screen and (min-width: 1100px){
      font-size: 24px;
      line-height: 29px;
    }
  }

  .digital, .contenedor-1, .contenedor-4, .contenedor-5, .businesscontenedor-1, 
  .businesscontenedor-4, .businesscontenedor-7, .businesscontenedor-2, .businesscontenedor-5, 
  .businesscontenedor-8, .business-title5, .informationcontenedor-1, .informationcontenedor-3, 
  .informationcontenedor-5, .informationcontenedor-4 {
    display:flex;
    justify-content: flex-start;
    padding: 1rem;
  }

  .digital, .contenedor-1, .businesscontenedor-1, 
  .businesscontenedor-4, .businesscontenedor-7{
    padding: 1rem 1.5rem 1rem 1.5rem;
  }

  .business-title1, .business-title3{
    padding: 1rem 1rem 0rem 1rem;
  }

  .business-title2, .business-title4{
    padding: 0rem 1rem 1rem 1rem;
  }
  .sales{
    padding: 1rem 0.5rem 0rem 0rem;
    min-width: 35%;
  }

  .bimov, .lakeit{
    padding: 1rem 0rem 1rem 1rem;
    max-width: 60%;
  }

  .bleo{
    padding: 1rem 0rem 1rem 1rem;
  }

  .digital p, .contenedor-1 p, .contenedor-3 p, .contenedor-4 p, .contenedor-5 p, .title1 p, 
  .businesscontenedor-1 p, .businesscontenedor-2 p, .businesscontenedor-4 p, .businesscontenedor-7 p, 
  .businesscontenedor-5 p, .businesscontenedor-8 p, .business-title1 p, .business-title3 p, .business-title5 p,
  .informationcontenedor-1 p, .informationcontenedor-3 p, .informationcontenedor-5 p{
    font-family: "Century Gothic", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 0px;
    @media screen and (max-width: 350px){
      font-size: 8px;
    }
    @media screen and (min-width: 1100px){
      font-size: 20px;
      letter-spacing: 1.25px;
      line-height: 24px;
    }
  }

  .digital p{
    color: #979797;
  }

  .contenedor-1, .contenedor-3 p, .contenedor-4 p, .contenedor-5 p, .title1 p{
    color: #6C99C3;
  }

  .informationcontenedor-1 p, .informationcontenedor-3 p, .informationcontenedor-5 p {
    color: #E1BB43;
  }

  .contenedor-3 p, .title1, {
    padding: 0rem 0rem 1rem 0rem;
  }

  .contenedor-3 p, .contenedor-4 p, .contenedor-5 p, .title1 p, .businesscontenedor-2 p, 
  .businesscontenedor-5 p, .businesscontenedor-8 p, .business-title1 p, .business-title3 p, .business-title5 p{
    font-size: 14px;
  }

  .inter p, .innova p, .hospital p, .business-title4 p, .business-title2 p, .informationcontenedor-4 p, .information-title1 p{
    font-family: "Century Gothic", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0rem;
    -webkit-text-stroke: 0.5px black;
    padding-top: 0.5rem;
  }

  .businesscontenedor-1 p, .businesscontenedor-4 p, .businesscontenedor-7 p, .businesscontenedor-2 p, 
  .businesscontenedor-5 p, .businesscontenedor-8 p, .business-title1 p, .business-title3 p, .business-title5 p{
    color: #809E6E;
  }

  .informationcontenedor-1 p, .informationcontenedor-3 p, .informationcontenedor-5 p{
    padding: 0rem 0rem 0rem 1rem;
  }

  .information-title1{
    display:flex;
    align-items: center;
  }

  .process{
    padding: 0rem 0rem 0rem 1rem;
  }

  .content{
    color: ${props => props.theme.colors.text.secondary};
    p{
      font-size: 0.875rem;
      line-height: 2em;
      margin-bottom: 1rem;
    }
    p:nth-last-child(1){
      margin-bottom: 0;
    }
  }
  .img-container{
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (min-width: 600px){
    padding: 3.75rem;
    .title{
      text-align: center;
      margin-bottom: 2rem;
    }
    .content{
      text-align: center;
      p{
        font-size: 1.125rem;
      }
    }
  }

  @media screen and (min-width: 1100px){
    .digital{
      display:flex;
      justify-content: center;
    }

    .conteiner-framework-2, .relleno-2, .conteiner-framework-4, .relleno-4{
      max-height: 15rem;
    }
    .conteiner-framework-3, .relleno-3{
      max-height: 22rem;
    }

    .customer{
      display:grid;
      grid-template-columns: repeat(6, 1fr);
      min-height: 13rem;
    }

    .contenedor-1{
      grid-column: 1 / 7;
      grid-row: 1;
      display:flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      max-height: 2rem;
    }
    .contenedor-2, .contenedor-4, .contenedor-5, .informationcontenedor-2, .informationcontenedor-4, .informationcontenedor-6{
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-height: 8rem;
    }

    .contenedor-4, .contenedor-5, .informationcontenedor-1, .informationcontenedor-3, .informationcontenedor-5{
      text-align: center;
    }
    .contenedor-3{
      max-height: 8rem;
    }
    .contenedor-6{
      grid-column: 5 / 7;
      rid-row: 2;
      max-height: 8rem;
    }
    .title1, .title2, .title3, .businesscontenedor-1 p, .businesscontenedor-4, .businesscontenedor-7{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hospital, .metrically, .businesscontenedor-1 p{
      padding: 0rem 0rem 0rem 1rem;
    }
    .information, .digital-business{
      display:grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .informationcontenedor-1, .businesscontenedor-1{
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    .informationcontenedor-2, .businesscontenedor-2{
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    .businesscontenedor-3{
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    .informationcontenedor-3, .businesscontenedor-4{
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    .informationcontenedor-4, .businesscontenedor-5 {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      text-align: center;
    }
    .businesscontenedor-6{
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }

    .informationcontenedor-5, .businesscontenedor-7 {
      grid-column: 3/ 4;
      grid-row: 1 / 2;
    }

    .informationcontenedor-6, .businesscontenedor-8{
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }

    .businesscontenedor-9{
      grid-column: 3 / 4;
      grid-row: 3 / 4;
    }

    .bimov, .lakeit, .bleo{
      padding:0;
    }
    .sales img{
      margin:0;
    }
    .bleo{
      padding: 0rem 0rem 0rem 2rem;
    }
    .informationcontenedor-4 p, .information-title1 p{
      font-size: 20px;
      font-weight: bold;
      line-height: 35px;
    }

    .businesscontenedor-6, .businesscontenedor-3, .digital-none2{
      display:flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .businesscontenedor-2, .businesscontenedor-5, .businesscontenedor-8{
      display:flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;
    }

    .digital-none, .internal-none, .internaloperation-none{
      color: #6BA14B;
      font-family: "Century Gothic", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      display: flex;
    }

    .process{
      padding: 6rem 0rem 0rem 0rem;
      text-align: center;
      display:flex;
      flex-direction: column;
      align-items: center;
    }
    .content{
      text-align: center;
      width: 40rem;
      margin:0;
  }
`
