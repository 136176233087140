import metrically from './img/Metrically-logo.png';
import bimov from './img/Bimov.png';
import bleo from './img/Bleo.png';
import lakeit from './img/Lakeit.png';
import backtowork from './img/Logo_BackToWork_color.png';
import salesforce from './img/Salesforce_logo.png';
import ux from './img/UX.png';
import vent from './img/Vent.png';

export default {
    "img1": metrically,
    "img2": bimov,
    "img3": bleo,
    "img4": lakeit,
    "img5": backtowork,
    "img6": salesforce,
    "img7": ux,
    "img8": vent
}